//REQUIRES <script src="https://cdn.jsdelivr.net/npm/ipfs/dist/index.min.js"></script>

import { printErrorWithPrefix, printWithPrefix, isUndefinedOrNull, fetchWithTimeout
} from "./utils.mjs";

const ipfs_gateways = [
  "https://wallet.xrplnft.art/ipfs/",
  "https://aesthetes.mypinata.cloud/ipfs/",
  "https://gateway.pinata.cloud/ipfs/",
  "https://ipfs.io/ipfs/"
];
const ipfs_fetch_timeout = 30 * 1000;

const IPFSFetchRecursive = async function(file_path, gateway_index){
  const _prefix = "IPFSFetchRecursive(): #" + gateway_index + ": " + file_path + " : ";
  
  if(gateway_index >= ipfs_gateways.length){
    //console.log(_prefix + "returning null");
    return null;
  }
  
  const _url = ipfs_gateways[gateway_index] + file_path;
  
  //console.log(_prefix + "fetching from " + ipfs_gateways[gateway_index] + "...");
  var response = null;
  try{
    const _response = await fetchWithTimeout(_url, { timeout: ipfs_fetch_timeout });
    if(!_response.ok){
      //printWithPrefix("fetch response is not ok", _prefix);
      throw new Error("fetch response is not ok");
    }
    printWithPrefix("fetch of " + _url + " resolved", _prefix);
    //console.log("_response: ", _response);
    response = _response;
  }
  catch(error){
    //printErrorWithPrefix(error, _prefix);
    response = await IPFSFetchRecursive(file_path, gateway_index + 1);
  }
  
  //console.log(_prefix + "returning response: ", response);
  return {
    "response": response,
    "url": _url
  };
}
export const IPFSFetch = async function(file_path){
  return IPFSFetchRecursive(file_path, 0);
}
