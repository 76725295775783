
//call this to detect if the web app is being opened inside XUMM
export const isXumm = function(){
  if(/xumm/i.test(navigator.userAgent)){
    return true;
  }
  
  return false;
}

function postXUMMCommand(XUMM_command_obj){
  if(typeof window.ReactNativeWebView !== "undefined"){
    window.ReactNativeWebView.postMessage(JSON.stringify(XUMM_command_obj));
  }
}

//call this to redirect the user to a specific URL
export const handleXUMMRedirection = function(_url){
  const XUMM_command_obj = {
    "command": "openBrowser",
    "url": _url
  };
  
  postXUMMCommand(XUMM_command_obj);
}

//call this to open a XUMM sign request flow
export const handleXUMMSignFlow = function(payload_uuid){
  const XUMM_command_obj = {
    "command": "openSignRequest",
    "uuid": payload_uuid
  };
  
  postXUMMCommand(XUMM_command_obj);
}

//call this to close the xApp (NOT NEEDED)
export const handleXUMMXAppClosure = function(){
  const XUMM_command_obj = {
    "command": "close",
    "refreshEvents": false
  };
  
  postXUMMCommand(XUMM_command_obj);
}


export const extractUUIDFromURL = function(_url){
  const _url_splitted = _url.split('/');
  
  return _url_splitted[_url_splitted.length - 1];
}
