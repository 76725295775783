import * as React from "react";

function BackArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={109}
      height={43}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.959 14.502c1.152-.468 1.926-1.422 1.926-2.862 0-2.052-1.62-3.24-4.428-3.24H52.02V21h5.796c3.15 0 4.734-1.224 4.734-3.366 0-1.71-.972-2.772-2.592-3.132zm-2.592-5.004c2.016 0 3.186.774 3.186 2.286s-1.17 2.286-3.186 2.286h-4.014V9.498h4.014zm.432 10.404h-4.446v-4.734h4.446c2.214 0 3.42.72 3.42 2.358 0 1.656-1.206 2.376-3.42 2.376zM75.005 21h1.404L70.65 8.4h-1.314L63.575 21h1.386l1.512-3.366h7.02L75.005 21zm-8.046-4.446l3.024-6.768 3.024 6.768H66.96zm16.83 4.554c1.855 0 3.51-.63 4.627-1.854l-.846-.846c-1.026 1.062-2.286 1.512-3.726 1.512-3.042 0-5.328-2.232-5.328-5.22 0-2.988 2.286-5.22 5.328-5.22 1.44 0 2.7.45 3.726 1.494l.846-.846c-1.116-1.224-2.772-1.836-4.608-1.836-3.798 0-6.606 2.718-6.606 6.408 0 3.69 2.808 6.408 6.588 6.408zM100.31 21h1.584l-5.832-6.894 5.454-5.706h-1.53l-7.398 7.614V8.4h-1.332V21h1.332v-3.312l2.574-2.61L100.31 21zM107 32.5a1.5 1.5 0 000-3v3zm-86.06-2.56a1.5 1.5 0 000 2.12l9.545 9.547a1.5 1.5 0 102.122-2.122L24.12 31l8.486-8.485a1.5 1.5 0 10-2.122-2.122L20.94 29.94zM107 29.5H22v3h85v-3z"
        fill="#fff"
      />
    </svg>
  );
}

export default BackArrow;
